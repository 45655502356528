import cx from 'classnames';
import { ErrorCircleFilledIcon, SuccessCircleFilledIcon } from './icons';

interface Props {
  message: string;
  variant?: 'error' | 'success';
  position?: 'top' | 'bottom';
}
export function BannerMessage({ message, position, variant }: Props) {
  return (
    <div
      className={cx(
        `font-size-xs absolute z-10 mt-2 flex w-full items-center rounded-lg p-2 text-left text-xs font-normal`,
        position === 'top' ? 'bottom-full' : 'top-full',
        variant === 'error' && 'bg-[#fcefee] text-[#D31400]',
        variant === 'success' && 'bg-[#ebfdf5] text-[#2CB87F]'
      )}
    >
      {variant === 'error' && <ErrorCircleFilledIcon className="mr-2" />}
      {variant === 'success' && <SuccessCircleFilledIcon className="mr-2" />}
      {message}
    </div>
  );
}
